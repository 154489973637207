import * as React from "react";
import Button from "@components/Button";
import styles from "./hero.module.scss";
import HeroImage from "../../../public/assets/images/hero.png";
import Image from "next/image";
import { gsap } from "gsap";
import Whatsapp from "@public/assets/icons/whatsapp.svg";

type Props = {};

const Hero: React.FC<Props> = (props) => {
  let imageRef = React.useRef<Array<HTMLDivElement | null>>([]);
  let textRef = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    let currentTextRef = textRef.current as gsap.TweenTarget;
    let currentImageRef = imageRef.current as gsap.TweenTarget;

    gsap.defaults({ ease: "Power4.out" });

    let tl1 = gsap.timeline();

    let tl2 = gsap.timeline();

    tl1.staggerFrom(
      currentTextRef,
      1.4,
      { delay: 1.4, autoAlpha: 0, y: 100 },
      0.2
    );

    tl2.staggerTo(
      currentImageRef,
      1.4,
      {
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        autoAlpha: 1,
        delay: 2,
        ease: "expo.out",
      },
      0.4
    );

    return () => {};
  }, []);

  return (
    <section className={styles["hero"]}>
      <div className={styles["hero__container"]}>
        <div className={styles["hero__left"]}>
          <div className={styles["hero__left__top"]}>
            <div className={styles["hero__left__top__hidden"]}>
              <div
                ref={(element) => {
                  textRef.current[0] = element;
                }}
                className={styles["hero__left__top__text"]}
              >
                Create a unique
              </div>
            </div>
            <div className={styles["hero__left__top__hidden"]}>
              <div
                ref={(element) => {
                  textRef.current[1] = element;
                }}
                className={styles["hero__left__top__text"]}
              >
                experience for
              </div>
            </div>
            <div className={styles["hero__left__top__hidden"]}>
              <div
                ref={(element) => {
                  textRef.current[2] = element;
                }}
                className={styles["hero__left__top__text"]}
              >
                your big day
              </div>
            </div>
          </div>

          <div className={styles["hero__left__bottom"]}>
            <div className={styles["hero__left__bottom__hidden"]}>
              <div
                ref={(element) => {
                  textRef.current[3] = element;
                }}
                className={styles["hero__left__bottom__text"]}
              >
                A modern way to stay organized and
              </div>
            </div>
            <div className={styles["hero__left__bottom__hidden"]}>
              <div
                ref={(element) => {
                  textRef.current[4] = element;
                }}
                className={styles["hero__left__bottom__text"]}
              >
                in control of your wedding
              </div>
            </div>
          </div>
          <div className={styles["hero__left__btnCon"]}>
            <div className={styles["hero__left__button"]}>
              <div
                ref={(element) => {
                  textRef.current[5] = element;
                }}
              >
                <Button
                  href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}register`}
                  newPage
                >
                  Start Your Wedding
                </Button>
              </div>
            </div>
            <div className={styles["hero__left__button"]}>
              <div
                ref={(element) => {
                  textRef.current[6] = element;
                }}
              >
                <Button
                  href={`${process.env.NEXT_PUBLIC_DEMO_WEDDING_WEBSITE}`}
                  newPage
                  theme="transparent"
                >
                  View Demo Website
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["hero__right"]}>
          <div
            className={styles["hero__right__orange"]}
            ref={(element) => {
              imageRef.current[0] = element;
            }}
          />
          <div
            ref={(element) => {
              imageRef.current[1] = element;
            }}
            className={styles["hero__right__image"]}
          >
            <Image
              src={HeroImage}
              alt="a couple hugging"
              placeholder="blur"
              priority
              quality={100}
            />
          </div>
        </div>
      </div>
      <a
        href="https://wa.me/2347013350169"
        className={styles["hero__whatsapp-icon"]}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Whatsapp />
      </a>
    </section>
  );
};

export default Hero;
